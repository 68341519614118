import { useRouter } from "next/router";

import { useStore } from "@nanostores/react";
import FullPageSpinner from "common/components/FullPageSpinner";
import { user } from "common/stores/UserStore";
import { loginRedirect } from "modules/auth/AuthUtils";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";

let redirectTimer;

interface LoginPageProps {
	returnUrl?: string;
	username?: string;
}

export const getServerSideProps = (async ({ query }) => {
	return {
		props: {
			returnUrl: (query.returnUrl as string) || "/",
			username: (query.username as string) || null,
		},
	};
}) satisfies GetServerSideProps<LoginPageProps>;

export default function LoginPage({
	returnUrl,
	username,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const router = useRouter();
	const $user = useStore(user);

	clearTimeout(redirectTimer);

	if (!$user.username) {
		redirectTimer = setTimeout(
			() => loginRedirect("auth0/login", returnUrl, "/", username),
			500
		);
		return <FullPageSpinner />;
	} else {
		redirectTimer = setTimeout(() => router.push("/"), 100);
		return <FullPageSpinner message="redirecting..." />;
	}
}
